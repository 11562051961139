import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { ValidationError } from "../adaptorHooks/validationError";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { FINALIZE_CONTRACT_MUTATION_KEY_ROOT, contractSnapshotsOfAssetContractsKey, contractStatusKey, multipleDeliveryGroupsKey, singleDeliveryGroupsKey } from "../../utils/react-query-key-factory";

async function putFinalizeContract(put: <In,Out>(url: string, body:In) => Promise<Out|null>,
  dealershipNumber: string, deliveryGroupId: string, unfinalize?: boolean): Promise<void|null>{
  let endpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/finalize`;
  if(unfinalize){
    endpoint += "?undo=true";
  }
  return put<any, void>(endpoint, {});
}

type FinalizeContract = {
  onError: (error: string[]) => void;
  onSuccess: () => void;
  deliveryGroupId: string;
  customerId: string;
  unfinalize?: boolean;
}

export function useFinalizeContract(dealershipNumber: string) {
  const { put } = useHttp();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();
  
  return useMutation(
    [FINALIZE_CONTRACT_MUTATION_KEY_ROOT],
    async (req: FinalizeContract) => await putFinalizeContract(put, dealershipNumber, req.deliveryGroupId, req.unfinalize),
    {
      onError(error, req: FinalizeContract, context){
        if(error instanceof ValidationError && error.validationErrors.length > 0){
          req.onError(error.validationErrors.map(e => e.message));
          if (error.validationErrors.length > 0) {
            error.validationErrors.forEach((errorString) =>
              analytics(emitErrorEvent, errorString, error.status)
            );
          }
        }
        else{
          req.onError(["Oops something went wrong. Please try again later"]);
          analytics(emitErrorEvent, "finalize contract error", (error as any).status ?? 500);
        }
      },
      onSuccess: (data, req, context) => {
        req.onSuccess();
      },
      onSettled: (data, error, req) => {
        queryClient.refetchQueries(singleDeliveryGroupsKey(dealershipNumber ,req.deliveryGroupId));
        queryClient.invalidateQueries(contractStatusKey(dealershipNumber, req.deliveryGroupId ));
        queryClient.invalidateQueries(multipleDeliveryGroupsKey(dealershipNumber, req.customerId));
        queryClient.invalidateQueries(contractSnapshotsOfAssetContractsKey(dealershipNumber, req.customerId, req.deliveryGroupId));
      },
    }
  );
}
