import { Box } from "@ally/metronome-ui";
import { TextHeadingStyled } from "../../DeliveryGroupTabControlerStyled";
import { SubHeaderStyle } from "./DeliveryGroupStyle";
import DeliveryGroupDropDown from "./DeliveryGroupDropDown";

const DeliveryGroupHeader: React.FC = () => {
  return (
    <>
      <TextHeadingStyled
        tag="h2"
        size="md"
        tabIndex={-1}
      >
        Delivery groups
      </TextHeadingStyled>
      <Box>
        <SubHeaderStyle>
          You can create multi-vehicle contracts by adding vehicles from the
          vehicles list to your delivery groups. To create your contract,
          finalize the purchase terms of each vehicle in your delivery group
          and update your Deal Status to Ready for Contract. Once
          you’ve finalized your delivery group, select <b>Create Contract </b>
          to view and sign your documents.
        </SubHeaderStyle>
        <DeliveryGroupDropDown type="ALL" />
      </Box>
    </>
  );
};

export default DeliveryGroupHeader;
