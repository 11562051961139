export const VEHICLE_QUERY_KEY_ROOT = 'vehicles';

export const ASSET_CONTRACT_QUERY_KEY_ROOT = 'asset-contract';
export const SINGLE_CUSTOMER_QUERY_KEY_ROOT = 'customer';
export const MULTI_CUSTOMERS_QUERY_KEY_ROOT = 'customers';
export const DEALERSHIP_INFO_QUERY_KEY_ROOT = 'get-dealership-info';
export const SINGLE_DELIVERY_GROUP_QUERY_KEY_ROOT = 'delivery-group';
export const MULTI_DELIVERY_GROUPS_QUERY_KEY_ROOT = 'delivery-groups';
export const CONTRACT_STATUS_QUERY_KEY_ROOT = 'contract-status';
export const CONTRACT_DOWNLOAD_QUERY_KEY_ROOT = "contract"
export const CONTRACT_SNAPSHOT_OF_ASSET_CONTRACT_QUERY_KEY_ROOT = 'contract-asset-contracts-snapshot';

export const BUYER_INFO_MUTATION_KEY_ROOT = 'use-buyer-info';
export const COBUYER_INFO_MUTATION_KEY_ROOT = 'use-cobuyer-info';
export const DEALER_INFO_MUTATION_KEY_ROOT = 'use-dealer-info';
export const ADD_DELIVERY_GROUP_MUTATION_KEY_ROOT = 'create-delivery-group';
export const DELETE_DELIVERY_GROUP_MUTATION_KEY_ROOT = 'delete-delivery-group';
export const EDIT_DELIVERY_GROUP_MUTATION_KEY_ROOT = 'edit-delivery-group';
export const EDIT_DELIVERY_GROUP_EMAIL_MUTATION_KEY_ROOT = 'edit-delivery-group-email';
export const SELECT_SIGNING_OPTION_KEY_ROOT = 'select-signing-option';

export const UPSERT_ASSET_CONTRACT_MUTATION_KEY_ROOT = 'upsert-asset-contract';
export const JOIN_AC_MUTATION_KEY_ROOT = 'join-ac-with-dg';
export const UNJOIN_AC_MUTATION_KEY_ROOT = 'unjoin-ac-with-dg';
export const CANCEL_CONTRACT_MUTATION_KEY_ROOT = 'cancel-contract';
export const CREATE_CONTRACT_MUTATION_KEY_ROOT = 'create-contract';
export const FINALIZE_CONTRACT_MUTATION_KEY_ROOT = 'finalize-contract';

export const ENVELOPE_STATUS_KEY_ROOT = 'envelope-status';

export function assignedVehiclesKey(dealershipNumber: string, customerId: string, deliveryGroupId: string | number) {
  const dgId = (typeof deliveryGroupId) == 'number' ? `${deliveryGroupId}` : deliveryGroupId;
  return [VEHICLE_QUERY_KEY_ROOT, { dealershipNumber }, { customerId }, { deliveryGroupId: dgId }];
}
export function unassignedVehiclesKey(dealershipNumber: string, customerId: string) {
  return [VEHICLE_QUERY_KEY_ROOT, { dealershipNumber }, { customerId }, { deliveryGroupId: undefined }];
}
export function contractSnapshotsOfAssetContractsKey(dealershipNumber: string, customerId: string, deliveryGroupId: string) {
  return [CONTRACT_SNAPSHOT_OF_ASSET_CONTRACT_QUERY_KEY_ROOT, { dealershipNumber }, { customerId }, { deliveryGroupId }];
}
export function assetContractKey(dealershipNumber: string, applicationId: string) {
  return [ASSET_CONTRACT_QUERY_KEY_ROOT, { dealershipNumber }, { applicationId }]
}
export function allAssetContractsForDealershipKey(dealershipNumber: string) {
  return [ASSET_CONTRACT_QUERY_KEY_ROOT, { dealershipNumber }]
}
export function singleCustomerKey(dealershipNumber: string, customerId: string) {
  return [SINGLE_CUSTOMER_QUERY_KEY_ROOT, { dealershipNumber }, { customerId }];
}
export function multipleCustomersKey(dealershipNumber: string) {
  return [MULTI_CUSTOMERS_QUERY_KEY_ROOT, { dealershipNumber }];
}
export function multipleDeliveryGroupsKey(dealershipNumber: string, customerId: string) {
  return [MULTI_DELIVERY_GROUPS_QUERY_KEY_ROOT, { dealershipNumber }, { customerId }];
}
export function singleDeliveryGroupsKey(dealershipNumber: string, deliveryGroupId: string) {
  return [SINGLE_DELIVERY_GROUP_QUERY_KEY_ROOT, { dealershipNumber }, { deliveryGroupId }]
}
export function contractStatusKey(dealershipNumber: string, deliveryGroupId: string) {
  return [CONTRACT_STATUS_QUERY_KEY_ROOT, { dealershipNumber }, { deliveryGroupId }];
}
export function contractDownloadKey(dealershipNumber: string, deliveryGroupId: string) {
  return [CONTRACT_DOWNLOAD_QUERY_KEY_ROOT, { dealershipNumber }, { deliveryGroupId }]
}

export function envelopeStatusKey(dealershipNumber: string, deliveryGroupId: string) {
  return [ENVELOPE_STATUS_KEY_ROOT, { dealershipNumber }, { deliveryGroupId }]
}