import { Button, ButtonGroup, useModalContext } from "@ally/metronome-ui";
import {
    TextHeading,
    TextBody
} from "@ally/metronome-ui";
import { ReactElement } from "react";

export const SigningOptionsModalContent: React.FC<{
    onClickHandler?: () => void;
    loading?: boolean;
    header: string;
    body: ReactElement;
    allytmln: string;
}> = ({ onClickHandler, loading, header, body, allytmln }) => {
    const { setOpen } = useModalContext();
    return (
        <>
            <TextHeading size="sm" tag="h3">{header}</TextHeading>
            <TextBody size="sm" tag="span">{body}</TextBody>
            <ButtonGroup>
                <Button
                    aria-label="Return"
                    text="Return"
                    variant="destructive"
                    onClick={onClickHandler}
                    loading={loading}
                    allytmln={allytmln}
                />
                <Button
                    aria-label="Close"
                    text="Close"
                    variant="link"
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => setOpen(false)}
                />
            </ButtonGroup>
        </>
    )
}
