import { useContext, useEffect } from "react";
import { useParams } from "react-router";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { TabContext, ContextType } from "../TabContext";
import { ContractProgressTracker } from "../../ProgressTracker/ContractProgressTracker";
import { useFundContract } from "../../../hooks/contractHooks/useFundContract";
import DeliveryGroupHeader from "./DeliveryGroupHeaders/DeliveryGroupHeader";
import { useAnalytics } from "../../../hooks/analytics/useAnalytics";
import { PageViews, emitPageViewEvent } from "../../../analytics/pageViews";
import ContractedDeliveryGroupDetailsNew from "./DeliveryGroupDetails/ContractedDeliveryGroupDetailsNew";
import {
    MailContractOuter,
    MailDocumentsStyled,
    MailingOptionsInner,
    MailingOptionsOuter,
    ConfirmButton,
    SigningOptionsButton,
    EditContractLink
} from "./MailContractStyled"; import { ButtonGroup } from "@ally/metronome-ui";
import { EditContractModal } from "../../Modal/EditContractModal";
import { ChangeSigningOptionsModal } from "../../Modal/ChangeSigningOptionsModal";

type Props = {
    isActiveTab: boolean;
    setHideRecontractForDG: (string: string) => void;
};
const MailContract: React.FC<Props> = ({ isActiveTab, setHideRecontractForDG }) => {
    const analytics = useAnalytics();
    const [dealershipInfo] = useDealershipInfo();
    const dealershipNumber = dealershipInfo?.dealershipNumber;
    const { id: customerId } = useParams() as any;
    const {
        saveActiveDeliveryGroupName,
        activeDeliveryGroupId,
    } = useContext(TabContext) as ContextType;
    const progressTracker = <ContractProgressTracker stepNumber={4} />;
    const fundContract = useFundContract(dealershipNumber);

    useEffect(() => {
        if (isActiveTab) {
            analytics(emitPageViewEvent, PageViews.DELIVERY_GROUP_CONTRACT_FUNDING_COMPLETE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActiveTab]);

    const fundContractAction = () => {
        const onSuccess = () => {
        }
        const onError = (errs: string[]) => {
            errs.map((err) => console.warn(err))
        }
        fundContract.mutate({
            deliveryGroupId: activeDeliveryGroupId!,
            customerId: customerId,
            onSuccess,
            onError
        });
        setHideRecontractForDG(activeDeliveryGroupId);
    };

    return (
        <>
            <DeliveryGroupHeader />
            <ContractedDeliveryGroupDetailsNew
                progressTracker={progressTracker}
                updateDeliveryGroupName={saveActiveDeliveryGroupName}
                deliveryGroupStatus={"CONTRACT_FINALIZED"}
            />
            <MailContractOuter>
                <MailDocumentsStyled>
                    <h3>Mail your documents</h3>
                    <p>Now that you’ve finalized your signatures, mail the following required documents to Ally:</p>
                    <ul>
                        <li><span>Signed multi-vehicle contract</span></li>
                        <li><span>Signed Ally approved credit application</span></li>
                        <li><span>Signed title application for all vehicles</span></li>
                        <li><span>Proof of insurance for all vehicles</span></li>
                        <li><span>Invoices or book out sheet for all vehicles</span></li>
                    </ul>
                </MailDocumentsStyled>
                <MailingOptionsOuter>
                    <b>Mailing options</b>
                    <MailingOptionsInner>
                        <div>
                            <b>FedEx (Including ground)</b>
                            <p>
                                {"Ally Financial\nSuite 2070\n4054 Willow Lake Blvd\nMemphis, TN 38153"}
                            </p>
                        </div>
                        <div>
                            <b>All other couriers:</b>
                            <p>
                                {"Ally Financial\nSuite 2070\n4054 Willow Lake Blvd\nMemphis, TN 38118"}
                            </p>
                        </div>
                    </MailingOptionsInner>
                    <p>We’ll start the funding process once we’ve received and reviewed your documents. Once you’ve mailed your contract, select <b>Confirm</b> to update your dashboard.</p>
                </MailingOptionsOuter>
                <ButtonGroup>
                    <ConfirmButton
                        text="Confirm"
                        ariaLabel="Confirm Button"
                        onClick={() => fundContractAction()}
                        loading={fundContract.isLoading}
                    />
                    <SigningOptionsButton>
                        <ChangeSigningOptionsModal buttonType="secondary" />
                    </SigningOptionsButton>
                    <EditContractLink>
                        <EditContractModal />
                    </EditContractLink>
                </ButtonGroup>
            </MailContractOuter>
        </>
    );
};

export default MailContract;

