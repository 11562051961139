import { useContext } from "react";
import { useDealershipInfo } from "../../hooks/useDealershipInfo";
import { CloseModalButton, ModalStyled, RecontractButton, RecontractModalButton } from "./SigningOptionsModalsStyled"
import { TabContext, ContextType } from "../Tab/TabContext";
import { useCancelContract } from "../../hooks/contractHooks/useCancelContract";
import { useParams } from "react-router";
import { TextBody, TextHeading, useModalContext } from "@ally/metronome-ui";

const RecontractModalTrigger: React.FC = () => {
    const { setOpen } = useModalContext();

    return (
        <RecontractButton
            aria-label="Recontract"
            text="Recontract"
            variant="secondary"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => setOpen(true)}
        />
    )
}

const ModalContent: React.FC = () => {
    const [dealershipInfo] = useDealershipInfo();
    const { dealershipNumber } = dealershipInfo || {};
    const { id: customerId } = useParams() as any;
    const {
        activeDeliveryGroupId,
    } = useContext(TabContext) as ContextType;
    const { setOpen } = useModalContext();

    const cancelContract = useCancelContract(dealershipNumber, customerId);

    const cancelContractAction = () => {
        const onSuccess = () => {
        }
        const onError = (errs: string[]) => {
            errs.map((err) => console.warn(err))
        }
        cancelContract.mutate({
            deliveryGroupId: activeDeliveryGroupId!,
            onSuccess,
            onError
        });
    }

    const body = <p>You should only go back if you need to recontract and make changes.
        Selecting <b>Recontract</b> will revoke your current contract. If that’s not what
        you want to do, select <b>Close</b>.</p>;
    return (
        <>
            <TextHeading size="sm" tag="h3">Recontract</TextHeading>
            <TextBody size="sm" tag="span">{body}</TextBody>
            <RecontractModalButton
                aria-label="Recontract"
                text="Recontract"
                variant="destructive"
                onClick={cancelContractAction}
                loading={cancelContract?.isLoading}
                allytmln="Recontract - Cancel Contract"
            />
            <CloseModalButton
                aria-label="Close"
                text="Close"
                variant="link"
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => setOpen(false)}
            />
        </>
    )
}

export const RecontractModal: React.FC = () => {
    return (
        <ModalStyled
            trigger={<RecontractModalTrigger />}
            content={
                <ModalContent />
            }
            disableFullScreenSmDown={true}
        />
    )
}
