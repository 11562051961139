import {
  Label,
  Space,
  TextBody,
  TextHeading,
  SingleDatePickerStatelessDEPRECATED as SingleDatePickerStateless,
} from "@ally/metronome-ui";
import moment, { Moment } from "moment";
import React, { useEffect, useRef, useState } from "react";
import "moment-timezone";
import { useHistory, useParams } from "react-router";
import { useCreateDeliveryGroup } from "../../../hooks/deliveryGroupHooks/useCreateDeliveryGroup";
import { useDealershipInfo } from "../../../hooks/useDealershipInfo";
import { useAddBatchAssetContractToDeliveryGroup } from "../../../hooks/assetContractHooks/useAddAssetToDeliveryGroup";
import {
  CreateDeliveryGroup,
  EditDeliveryGroup,
} from "../../../model/delivery-group.model";
import { Heading } from "../../Dashboards/SingleCustomerDashboard/VehicleDashboard/VehicleDashboardStyles";
import { ContextType, TabContext } from "../../Tab/TabContext";
import {
  SubHeader,
  BoxStyle,
  DeliveryFormFieldStyle,
  LabelStyle,
  ButtonContainer,
  ButtonStyle,
  LinkButtonStyle,
  SingleDatePickerContainer,
  ModalStyled,
  FirstPaymentTextBody,
  FirstPaymentModalStyled,
  CancelModalStyled,
  DeleteModalStyled,
} from "./ActionDeliveryGroupStyles";
import { useEditDeliveryGroup } from "../../../hooks/deliveryGroupHooks/useEditDeliveryGroup";
import { useGetDeliveryGroup } from "../../../hooks/deliveryGroupHooks/useGetDeliveryGroup";
import { useGetCustomer } from "../../../hooks/customerHooks/useGetCustomer";
import { useDeleteDeliveryGroup } from "../../../hooks/deliveryGroupHooks/useDeleteDeliveryGroup";
import {
  CancelButtonModalTrigger,
  CancelModalContent,
  DeleteButtonModalTrigger,
  DeleteDeliveryGroupModalContent,
  ModalTrigger,
} from "./ActionDeliveryGroupModals";
import { ContractProgressTracker } from "../../ProgressTracker/ContractProgressTracker";
import { ActiveTabs } from "../../../constants/enums";
import { useDeliveryGroupFormErrors } from "../../../hooks/analytics/useDeliveryGroupFormErrors";

type Props = {
  editOrCreate: "create" | "edit";
};
const ActionDeliveryGroups: React.FC<Props> = ({ editOrCreate }) => {
  const {
    selectionGroup,
    saveSelection,
    saveVehicleIdsToAddToDg,
    vehicleIdsToAddToDg,
    saveDisplaySuccessfulAction,
    activeDeliveryGroupId,
    saveActiveDeliveryGroupId,
  } = React.useContext(TabContext) as ContextType;

  const WIRE_REQUEST_DATE_DAYS_ADVANCE = 90;
  const DAY_COMPARE_FORMAT = "YYYYMMDD";
  const STANDARD_DATE_FORMAT = "MM/DD/YYYY";
  const TIMEZONE = "America/New_York";

  const [initialClick, setInitialClick] = React.useState(false);
  const currentContractDateRef = useRef("");
  const currentFirstPaymentRef = useRef("");
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const { id } = useParams() as any;
  const history = useHistory();

  const createDg = useCreateDeliveryGroup(dealershipNumber, id);
  const { mutateAsync: addAcsToDg } = useAddBatchAssetContractToDeliveryGroup(
    dealershipNumber
  );
  const deleteDg = useDeleteDeliveryGroup(dealershipNumber, id);
  const editDg = useEditDeliveryGroup(
    dealershipNumber,
    id,
    activeDeliveryGroupId
  );
  const { data: currentDeliveryGroup } = useGetDeliveryGroup(
    dealershipNumber,
    activeDeliveryGroupId
  );
  const editMode = editOrCreate === "edit" && !!currentDeliveryGroup;

  const [groupNameError, setGroupNameErrorMessage] = useState("");
  const [customerNameError, setCustomerNameErrorMsg] = useState("");
  const [contractDateErrorMsg, setContractDateErrorMsg] = useState("");
  const [firstPaymentErrorMsg, setFirstPaymentErrorMsg] = useState("");
  const notEditModeAndNoContractDate =
    editMode &&
    currentDeliveryGroup?.contract_date !== null &&
    currentDeliveryGroup?.contract_date !== undefined;
  const [disableFirstPaymentField, setDisableFirstPaymentField] = useState(
    !notEditModeAndNoContractDate
  );
  const { data: customerData } = useGetCustomer(dealershipNumber, id);
  const dirtyForm = useRef(false);
  const contractDateFieldTouch = useRef(false);

  const initialRender = useRef(true);
  const [isPending, setIsPending] = useState(false);

  const errors = {
    groupName: groupNameError, customerName: customerNameError,
    contractDate: contractDateErrorMsg, firstPayment: firstPaymentErrorMsg
  };
  Object.keys(errors).forEach(key => errors[key].length === 0 && delete errors[key]);
  useDeliveryGroupFormErrors(errors);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else if (!initialRender.current && contractDateFieldTouch.current) {
      contractDateFieldTouch.current = false;
      const el = document.getElementById(
        "first_payment_due_date"
      ) as HTMLInputElement;
      el.value = "";
      updateLocalDgField("first_payment_due_date", null);
    }

    if (!customerData?.company) {
      updateLocalDgField("customer_name", customerData?.company ?? "");
    }
    //TODO address mutable dependancy
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentContractDateRef.current]);


  const navigateOnCancel: () => void = () => {
    if (editMode) {
      saveSelection({
        destinationTab: ActiveTabs.DELIVERY_GROUPS,
      });
    } else {
      saveSelection({
        destinationTab: selectionGroup.sourceTab,
      });
    }
    history.replace({ search: null });
  };

  const deleteDgActionComposer: (cb: () => void) => () => void = (
    cb: () => void
  ) => {
    return () => {
      if (activeDeliveryGroupId) {
        const postProcessSuccess = () => {
          saveActiveDeliveryGroupId(undefined);
          cb();
        };
        const postProcess = () => {
          saveSelection({
            destinationTab: ActiveTabs.DELIVERY_GROUPS,
          });
          history.replace({ search: null });
        };
        deleteDg.mutate(`${activeDeliveryGroupId}`, {
          onSuccess: (_) => postProcessSuccess(),
          onSettled: (_) => postProcess(),
        });
      }
    };
  };

  //TODO: possible factory function but should at least ensure delivery status is enum or state function is created
  const [deliveryGroup, setDeliveryGroup] = useState({
    customer_id: editMode ? currentDeliveryGroup.customer_id : id,
    group_name: editMode ? currentDeliveryGroup.group_name : "",
    delivery_status: editMode ? currentDeliveryGroup.delivery_status : "OPEN",
    customer_name: customerData?.company ?? "",
    contract_date:
      editMode && currentDeliveryGroup.contract_date
        ? String(currentDeliveryGroup.contract_date).split("T")[0]
        : undefined,
    first_payment_due_date:
      editMode && currentDeliveryGroup.first_payment_due_date
        ? String(currentDeliveryGroup.first_payment_due_date).split("T")[0]
        : undefined,
    dgType: editMode ? "edit" : "create",
  });

  const handleGroupNameValidation = (event: any) => {
    let hasGroupNameError = false;
    let groupName;
    if (!event.target) {
      groupName = event;
    } else {
      groupName = event.target.value;
    }

    if (groupName.trim().length === 0) {
      setGroupNameErrorMessage("Enter the group name to continue.");
      hasGroupNameError = true;
    } else if (groupName.trim().length < 10 || groupName.trim().length > 50) {
      setGroupNameErrorMessage(
        "Your delivery group name must contain 10-50 characters."
      );
      hasGroupNameError = true;
    } else {
      setGroupNameErrorMessage("");
      hasGroupNameError = false;
    }
    return hasGroupNameError;
  };
  const handleChangeGroupName = (event: any) => {
    dirtyForm.current = true;
    if (initialClick) {
      handleGroupNameValidation(event);
    }

    updateLocalDgField(event.target.name, event.target.value);
  };

  const handleChangeCustomerName = (event: any) => {
    dirtyForm.current = true;
    if (initialClick) {
      handleCustomerNameValidation(event);
    }
    updateLocalDgField(event.target.name, event.target.value);
  };

  const handleCustomerNameValidation = (event: any) => {
    let hasCustomNameValidationError = false;
    let customerName;
    if (!event.target) {
      customerName = event;
    } else {
      customerName = event.target.value;
    }

    if (customerName.trim().length === 0) {
      setCustomerNameErrorMsg("Enter the customer name to continue.");
      hasCustomNameValidationError = true;
    } else if (
      customerName.trim().length < 2 ||
      customerName.trim().length > 100
    ) {
      setCustomerNameErrorMsg(
        "Your customer name must contain 2-100 characters."
      );
      hasCustomNameValidationError = true;
    } else {
      hasCustomNameValidationError = false;
      setCustomerNameErrorMsg("");
    }
    return hasCustomNameValidationError;
  };

  const handleFormError = (
    currentContractDate: string,
    currentFirstPaymentDate: string
  ) => {
    let hasDateError = false;
    if (currentContractDate && !isValidDate(currentContractDate)) {
      hasDateError = true;
      updateLocalDgField("contract_date", null);
      setContractDateErrorMsg("Re-enter your date in mm/dd/yyyy format.");
    } else if (
      isValidDate(currentContractDate) &&
      isOutsideRange(moment(currentContractDate))
    ) {
      hasDateError = true;
      setContractDateErrorMsg("That date you selected isn’t available.");
    } else if (
      currentContractDate &&
      isValidDate(currentContractDate) &&
      !isOutsideRange(moment(currentContractDate)) &&
      !isValidDate(currentFirstPaymentDate) &&
      currentFirstPaymentDate.length > 0
    ) {
      hasDateError = true;
      setFirstPaymentErrorMsg("Re-enter your date in mm/dd/yyyy format.");
    }

    if (
      isValidDate(currentContractDate) &&
      !isOutsideRange(moment(currentContractDate)) &&
      isValidDate(currentFirstPaymentDate) &&
      isOutsideRangeFirstPayment(moment(currentFirstPaymentDate))
    ) {
      hasDateError = true;
      setFirstPaymentErrorMsg(
        "Select a date that’s 30-45 days after your contract date."
      );
    }

    const hasGroupNameError = handleGroupNameValidation(
      deliveryGroup.group_name
    );
    const hasCustomNameValidationError = handleCustomerNameValidation(
      deliveryGroup.customer_name
    );
    return hasDateError || hasGroupNameError || hasCustomNameValidationError;
  };

  const contentAddMode =
    "Add your delivery group name and contract details and select Save to create your delivery group. Any dates you select are estimates and might change.";
  const contentEditMode =
    "Make any changes to your delivery group name, contract details and select Save. Any dates you select are estimates and might change.";
  const pContent = editMode ? contentEditMode : contentAddMode;

  const updateLocalDgField = (name: string, value: any) => {
    setDeliveryGroup( prev => {
      return {
      ...prev,
      [name]: value,
    }});
  };

  // TODO: this is where the timezone problem is
  function convertStringToMoment(
    day?: string,
    format = STANDARD_DATE_FORMAT
  ): moment.Moment {
    const parsedDay =
      day ||
      moment()
        .tz(TIMEZONE)
        .format(STANDARD_DATE_FORMAT);
    return moment.tz(parsedDay, format, TIMEZONE);
  }

  const handleSubmit = (event: any) => {
    setIsPending(true);
    event.preventDefault();
    let hasFormError = false;

    if (editMode) {
      if (deliveryGroup.contract_date && !currentContractDateRef.current) {
        currentContractDateRef.current = moment(
          deliveryGroup.contract_date
        ).format(STANDARD_DATE_FORMAT);
      }

      if (
        deliveryGroup.first_payment_due_date &&
        !currentFirstPaymentRef.current
      ) {
        currentFirstPaymentRef.current = moment(
          deliveryGroup.first_payment_due_date
        ).format(STANDARD_DATE_FORMAT);
      }
    }

    hasFormError = handleFormError(
      currentContractDateRef.current,
      currentFirstPaymentRef.current
    );

    if (hasFormError) {
      setIsPending(false);
    }

    if (isValidDate(deliveryGroup.contract_date)) {
      updateLocalDgField("contract_date", deliveryGroup.contract_date);
    }
    if (isValidDate(deliveryGroup.first_payment_due_date)) {
      updateLocalDgField(
        "first_payment_due_date",
        deliveryGroup.first_payment_due_date
      );
    }

    if (
      initialClick &&
      contractDateErrorMsg.length === 0 &&
      groupNameError.length === 0 &&
      firstPaymentErrorMsg.length === 0 &&
      customerNameError.length === 0
    ) {
      initialRender.current = true;
      actionDeliveryGroup();
    } else if (!initialClick && !hasFormError) {
      initialRender.current = true;
      actionDeliveryGroup();
    }
    setInitialClick(true);
  };

  const actionDeliveryGroup = () => {
     
    deliveryGroup.customer_name = deliveryGroup.customer_name.trim();
    deliveryGroup.group_name = deliveryGroup.group_name.trim();

    const onCreateAndAdd = (newDgId: string) => {
      const a = vehicleIdsToAddToDg.map((i) => i.id);
      saveActiveDeliveryGroupId(newDgId);
     
      addAcsToDg({
        applicationIds: a,
        customerId: id,
        deliveryGroupId: newDgId
      }).then((_) => {
        saveVehicleIdsToAddToDg([]);
        saveSelection({
          destinationTab: ActiveTabs.DELIVERY_GROUPS,
        });
        history.replace({ search: null });
      });

      saveDisplaySuccessfulAction("created a new delivery group");
    };

    const onCreateWithoutAdd = (newDgId: string) => {
      saveSelection({
        destinationTab: ActiveTabs.DELIVERY_GROUPS,
      });
      saveActiveDeliveryGroupId(newDgId);
      saveDisplaySuccessfulAction("created a new delivery group");
      history.replace({ search: null });
    };

    const onEdit = () => {
      saveSelection({
        destinationTab: ActiveTabs.DELIVERY_GROUPS,
      });
      saveActiveDeliveryGroupId(activeDeliveryGroupId);
      saveDisplaySuccessfulAction("edited your delivery group");
      history.replace({ search: null });
    };

    if (editMode && deliveryGroup.dgType === "edit") {
      editDg.mutate({
        deliveryGroup: deliveryGroup as EditDeliveryGroup,
        onEdit: onEdit,
      });
    } else if (!editMode && deliveryGroup.dgType === "create") {
      createDg.mutate({
        deliveryGroup: deliveryGroup as CreateDeliveryGroup,
        onCreate: (newDgId) => {
          if (vehicleIdsToAddToDg.length > 0) {
            onCreateAndAdd(newDgId);
          } else {
            onCreateWithoutAdd(newDgId);
          }
        },
      });
    }
  };

  // Date exists and is valid
  const isValidDate = (date?: any): boolean => {
    return !!date && moment(date, STANDARD_DATE_FORMAT, true).isValid();
  };

  const isValidDateString = (date?: string): boolean =>
    !!date && moment(date, STANDARD_DATE_FORMAT, true).isValid();

  const onDateChangeContractDate = (date: Moment) => {
    updateLocalDgField("first_payment_due_date", null);
    if (date) {
      updateLocalDgField(
        "contract_date",
        date ? date.toISOString().split("T") : null
      );
    }
  };

  const onDateChangeFirstPaymentDate = (date: Moment) => {
    if (date) {
      updateLocalDgField(
        "first_payment_due_date",
        date ? date.toISOString().split("T") : null
      );
    }
  };

  const isOutsideRangeFirstPayment = (day: moment.Moment): boolean => {
    const minDate = getMinContractDate();
    const maxDate = minDate
      .clone()
      .add(15, "days")
      .endOf("day");

    return isBeforeDay(day, minDate) || isAfterDay(day, maxDate);
  };

  const isAfterDay = (
    day: moment.Moment,
    compareDay: moment.Moment
  ): boolean => {
    return (
      day.format(DAY_COMPARE_FORMAT) > compareDay.format(DAY_COMPARE_FORMAT)
    );
  };

  const isBeforeDay = (
    day: moment.Moment,
    compareDay: moment.Moment
  ): boolean => {
    return (
      day.format(DAY_COMPARE_FORMAT) < compareDay.format(DAY_COMPARE_FORMAT)
    );
  };

  const getWireMinDate = (): moment.Moment => {
    return moment()
      .tz(TIMEZONE)
      .subtract(60, "days")
      .startOf("day");
  };

  const getMinContractDate = (): moment.Moment => {
    return moment(deliveryGroup.contract_date)
      .add(30, "days")
      .startOf("day");
  };

  // Props for disabling dates on DatePicker
  const isOutsideRange = (day: moment.Moment): boolean => {
    const minDate = getWireMinDate();
    const maxDate = minDate
      .clone()
      .add(WIRE_REQUEST_DATE_DAYS_ADVANCE, "days")
      .endOf("day");

    return isBeforeDay(day, minDate) || isAfterDay(day, maxDate);
  };

  const handleChangeContractDate = (dateString: string) => {
    dirtyForm.current = true;
    contractDateFieldTouch.current = true;
    const isOutOfRange = isOutsideRange(convertStringToMoment(dateString));
    if (
      !deliveryGroup.first_payment_due_date ||
      !currentFirstPaymentRef.current
    ) {
      updateLocalDgField("first_payment_due_date", null);
      setFirstPaymentErrorMsg("");
    }

    if (initialClick) {
      if (isValidDateString(dateString)) {
        setContractDateErrorMsg("");
        setFirstPaymentErrorMsg("");
        if (isOutOfRange) {
          setContractDateErrorMsg("That date you selected isn’t available.");
        }
      } else if (dateString.length === 0) {
        setContractDateErrorMsg("");
      } else {
        setContractDateErrorMsg("Re-enter your date in mm/dd/yyyy format.");
      }
    }

    if (isValidDateString(dateString) && !isOutOfRange) {
      updateLocalDgField("contract_date", dateString);
      setDisableFirstPaymentField(false);
    } else {
      updateLocalDgField("contract_date", null);
      setDisableFirstPaymentField(true);
    }

    if (dateString.length === 1) {
      updateLocalDgField("contract_date", null);
    }

    currentContractDateRef.current = dateString;
  };

  const handleChangeFirstPaymentDate = (dateString: string) => {
    dirtyForm.current = true;
    const isOutOfRange = isOutsideRangeFirstPayment(
      convertStringToMoment(dateString)
    );
    if (initialClick) {
      if (isValidDateString(dateString)) {
        setFirstPaymentErrorMsg("");
        if (isOutOfRange) {
          setFirstPaymentErrorMsg(
            "Select a date that’s 30-45 days after your contract date."
          );
        }
      } else if (!isValidDateString(dateString) && dateString.length > 0) {
        setFirstPaymentErrorMsg("Re-enter your date in mm/dd/yyyy format.");
      }
    }

    if (dateString.length === 0) {
      setFirstPaymentErrorMsg("");
      updateLocalDgField("first_payment_due_date", null);
    }

    if (!isValidDateString(dateString)) {
      updateLocalDgField("first_payment_due_date", null);
    }

    if (isValidDateString(dateString)) {
      updateLocalDgField("first_payment_due_date", dateString);
    }

    currentFirstPaymentRef.current = dateString;
  };

  return (
    <div>
      <SubHeader>{pContent}</SubHeader>
      <ContractProgressTracker stepNumber={0} />
      <form allytm-form-tagged="true" >
        <BoxStyle p={["sm", "md"]} height="100%">
          <DeliveryFormFieldStyle
            variant="input"
            inputType="text"
            inputWidth="350px"
            labelContent="Delivery group name"
            name="group_name"
            value={deliveryGroup.group_name}
            maxLength={50}
            minLength={10}
            onChange={handleChangeGroupName}
            errorNotification={{ message: groupNameError }}
          />
          <Heading>Contract details</Heading>
          <DeliveryFormFieldStyle
            variant="input"
            inputType="text"
            inputWidth="350px"
            labelContent="Customer name"
            name="customer_name"
            maxLength={100}
            minLength={2}
            required
            value={deliveryGroup.customer_name}
            onChange={handleChangeCustomerName}
            errorNotification={{ message: customerNameError }}
          />

          <Label htmlFor="contact_date">
            <LabelStyle className="contract_date">Contract date (optional)</LabelStyle>
          </Label>
          <SingleDatePickerContainer id="contract_date_container">
            <SingleDatePickerStateless
              id="contract_date"
              date={
                deliveryGroup.contract_date
                  ? moment(deliveryGroup.contract_date)
                  : null
              }
              onDateChange={onDateChangeContractDate}
              disabled={undefined}
              ariaLabel={"contract date"}
              keepOpenOnDateSelect={undefined}
              initialVisibleMonth={undefined}
              hidePlaceholder={false}
              onChange={handleChangeContractDate}
              isOutsideRange={isOutsideRange}
              errorMessage={contractDateErrorMsg}
            />
            <ModalStyled
              trigger={<ModalTrigger id="contract_date_modal" />}
              content={
                <div>
                  <Space mb="md" mt="md">
                    <TextHeading size="sm" tag="h3" tabIndex={0}>
                      Contract Date
                    </TextHeading>
                    <TextBody size="sm" tag="p" tabIndex={0}>
                      <p>This is the date you&#39;ll sign your contract.</p>
                    </TextBody>
                  </Space>
                </div>
              }
              dataTrackRegion="example-modal"
            />
          </SingleDatePickerContainer>

          <Label htmlFor="contact_date">
            <LabelStyle>First payment due date (optional)</LabelStyle>
          </Label>
          <SingleDatePickerContainer id="first_payment_due_date_container">
            <SingleDatePickerStateless
              id="first_payment_due_date"
              onDateChange={onDateChangeFirstPaymentDate}
              date={
                deliveryGroup.first_payment_due_date
                  ? moment(deliveryGroup.first_payment_due_date)
                  : null
              }
              disabled={disableFirstPaymentField}
              ariaLabel={"first payment due date"}
              keepOpenOnDateSelect={undefined}
              initialVisibleMonth={undefined}
              hidePlaceholder={false}
              errorMessage={firstPaymentErrorMsg}
              onChange={handleChangeFirstPaymentDate}
              isOutsideRange={isOutsideRangeFirstPayment}
            />

            <FirstPaymentModalStyled
              role="dialog"
              trigger={<ModalTrigger id="first_payment_modal" />}
              content={
                <div>
                  <Space mb="md" mt="sm">
                    <TextHeading size="sm" tag="h3" tabIndex={-1}>
                      First Payment Due Date
                    </TextHeading>
                  </Space>
                  <FirstPaymentTextBody size="sm" tag="p" tabIndex={0}>
                    <p>
                      You need to select a date that’s 30-45 days after the day
                      you sign your contract. Any dates you can’t select are
                      outside of that window and unavailable
                    </p>
                  </FirstPaymentTextBody>
                </div>
              }
              dataTrackRegion="example-modal"
            />
          </SingleDatePickerContainer>

          <ButtonContainer>
            <ButtonStyle
              aria-label="save"
              text="Save"
              variant="primary"
              allytmln={`${editOrCreate}-group`}
              onClick={handleSubmit}
              loading={isPending}
              disabled={isPending}
            />
            {editMode && (
              <DeleteModalStyled
                trigger={<DeleteButtonModalTrigger />}
                content={
                  <DeleteDeliveryGroupModalContent
                    deleteActionComposer={deleteDgActionComposer}
                  />
                }
              />
            )}

            {!dirtyForm.current && (
              <LinkButtonStyle
                aria-label="Cancel"
                allytmln={`cancel-${editOrCreate}-group`}
                text="Cancel"
                variant="link"
                onClick={navigateOnCancel}
              />
            )}
            {dirtyForm.current && (
              <CancelModalStyled
                trigger={<CancelButtonModalTrigger />}
                content={<CancelModalContent setActions={navigateOnCancel} />}
              />
            )}
          </ButtonContainer>
        </BoxStyle>
      </form>
    </div>
  );
};

export default ActionDeliveryGroups;
