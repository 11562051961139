import { TextHeading, useModalContext } from "@ally/metronome-ui";
import React, { useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router";
import { ContextType, TabContext } from "../../../../Tab/TabContext";
import {
  ButtonStyle,
  LinkButtonStyle,
  MaxVehicleMessageContainer,
  ModalStyled,
  OptionHeading,
  StyledIcon,
  TextBodyStyled,
} from "./OptionsStickyNavStyles";
import { muiInfoFill } from "@ally/metronome-icons/dist/cjs";
import SelectDeliveryGroup from "../SelectDeliveryGroup";
import { ActiveTabs } from "../../../../../constants/enums";
import { VehicleMetadata } from "./OptionsStickyNav";
import { useGetDeliveryGroups } from "../../../../../hooks/deliveryGroupHooks/useGetDeliveryGroups";
import { useDealershipInfo } from "../../../../../hooks/useDealershipInfo";
import { Identifiable } from "../../../../../model/identifiable";

type Props = {
  selectedVehicles: VehicleMetadata[];
  handleCancel: (arg: string) => void;
};

const ModalTrigger: React.FC<{
  selectedVehicles: Identifiable[];
}> = ({ selectedVehicles }) => {
  const plural = selectedVehicles.length > 1 ? "s" : "";
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const { id: customerId } = useParams() as any;
  const { data, isSuccess } = useGetDeliveryGroups(
    dealershipNumber,
    customerId
  );
  const { setOpen } = useModalContext();
  const buttonRef = useRef(null);
  useEffect(() => {
    buttonRef?.current?.focus();
  }, []);

  if (isSuccess && data.length > 0) {
    return (
      <ButtonStyle
        ref={buttonRef}
        aria-label="Add Vehicle to Group"
        text={`Add Vehicle${plural} to Group`}
        variant="primary"
        allytmln="select-vehcile-to-add-to-group"
        onClick={() => setOpen(true)}
      />
    );
  }

  return <></>;
};

const ModalContent: React.FC<{
  selectedVehicles: Identifiable[];
}> = ({ selectedVehicles }) => {
  return (
    <>
      <TextHeading size="sm" tag="h1" focusOnMount>
        Add Vehicle to Group
      </TextHeading>
      <TextBodyStyled size="sm" tag="aside">
        {selectedVehicles.length === 1 && (
          <p tabIndex={0}>
            You’re about to add {selectedVehicles.length} vehicle to
            a delivery group.
          </p>
        )}
        {selectedVehicles.length > 1 && (
          <p tabIndex={0}>
            You’re about to add {selectedVehicles.length} vehicles
            to a delivery group.
          </p>
        )}
        <p tabIndex={0}>
          Select the group you want to add the vehicles to and
          select Continue, or Go Back if you need to make any
          changes.
        </p>
      </TextBodyStyled>
      <SelectDeliveryGroup selectedVehicles={selectedVehicles} />
    </>
  )
}

export const UnassignedVehicleStickyNav: React.FC<Props> = ({
  selectedVehicles,
  handleCancel,
}) => {
  const {
    saveSelection,
    saveVehicleIdsToAddToDg,
  } = React.useContext(TabContext) as ContextType;
  const history = useHistory();

  const createGroup = () => {
    const params = new URLSearchParams();
    params.append("action", "create-group");
    history.push({ search: params.toString() });
    saveSelection({
      destinationTab: ActiveTabs.DELIVERY_GROUPS,
    });
    saveVehicleIdsToAddToDg(selectedVehicles);
  };

  const totalRowCount = selectedVehicles.length;
  return (
  <>
      {totalRowCount <= 100 && (
        <>
          {totalRowCount < 2 && (
            <OptionHeading>
              Options available for a <b>single vehicle</b>.
            </OptionHeading>
          )}
          {totalRowCount >= 2 && (
            <OptionHeading>
              Options available for <b>multiple vehicles [{totalRowCount}]</b>
              .
            </OptionHeading>
          )}
          <ModalStyled
            role="dialog"
            trigger={<ModalTrigger selectedVehicles={selectedVehicles} />}
            content={<ModalContent selectedVehicles={selectedVehicles} />}
            dataTrackRegion="selected-vehicle-modal"
          />

          <ButtonStyle
            aria-label="create group"
            text="Create Group"
            variant="primary"
            allytmln="create-group"
            onClick={createGroup}
          />

          <LinkButtonStyle
            text="Cancel"
            variant="link"
            allytmln="cancel-vehicle-actions-sticky-nav"
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleCancel("")}
          />
        </>

      )}

      {totalRowCount > 100 && (
        <MaxVehicleMessageContainer>
          <StyledIcon
            icon={muiInfoFill}
            size="lg"
            fill="warning"
            ariaHidden
          /> 
          <span>You can only add up to 100 vehicles to your delivery group. Remove {totalRowCount-100} vehicles to create your group.</span>
        </MaxVehicleMessageContainer>
      )}
    </>
  );
};
