import { useContext } from "react";
import { useDealershipInfo } from "../../hooks/useDealershipInfo";
import { SigningOptionsModalContent } from "./SigningOptionsModals";
import { LinkStyled, ModalStyled } from "./SigningOptionsModalsStyled"
import { TabContext, ContextType } from "../Tab/TabContext";
import { useResetSigningOption } from "../../hooks/docusignHooks/useResetSigningOption";
import { useModalContext } from "@ally/metronome-ui";


export const ChangeSigningOptionsModalTrigger: React.FC<{
    buttonType: string,
}> = ({ buttonType }) => {
    const buttonText = buttonType === "link" ? "Back to signing options" : "Change signing options"
    const { setOpen } = useModalContext();
    return (
        <LinkStyled
            aria-label="Back to signing options"
            text={buttonText}
            variant={buttonType}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => setOpen(true)}
        />
    )
}

export const ChangeSigningOptionsModal: React.FC<{ buttonType: string }> = ({ buttonType }) => {
    const [dealershipInfo] = useDealershipInfo();
    const { dealershipNumber } = dealershipInfo || {};
    const {
        activeDeliveryGroupId,
    } = useContext(TabContext) as ContextType;

    const { mutate: resetSigningOption, isLoading } = useResetSigningOption(dealershipNumber, activeDeliveryGroupId);

    const header = "Return to signing options";
    const body = <p>You should only go back if you need to choose a new signing option.
        Selecting <b>Return</b> will revoke your current contract. If that’s not what
        you want to do, select <b>Close</b>.</p>;

    return (
        <ModalStyled
            trigger={<ChangeSigningOptionsModalTrigger buttonType={buttonType} />}
            content={
                <SigningOptionsModalContent
                    onClickHandler={() => resetSigningOption()}
                    loading={isLoading}
                    header={header}
                    body={body}
                    allytmln="Return - Signing Options"
                />
            }
            disableFullScreenSmDown={true}
        />
    )
}
