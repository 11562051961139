import React from "react";
import {
  TextBody,
  TextHeading,
  useModalContext,
} from "@ally/metronome-ui";
import "moment-timezone";
import {
  LinkButtonStyle,
  ModalButtonStyle,
  CancelButtonStyle,
  ModalButtonContainer,
  ContinueButtonStyle,
} from "./ActionDeliveryGroupStyles";
import { EditButtonStyled } from "../../Tab/DeliveryGroupTabViews/DeliveryGroupDetails/DeliveryGroupDetailsStyled";

export const ModalTrigger: React.FC<{ id: string }> = ({
  id,
}) => {
  const { setOpen } = useModalContext();
  return (
    <ModalButtonStyle
      variant="primary"
      allytmln={`tool-tip-for-${id}`}
      id={id}
      text="What's this?"
      onClick={() => setOpen(true)}
    />
  );
};

export const CancelButtonModalTrigger: React.FC = () => {
  const { setOpen } = useModalContext();
  return <LinkButtonStyle aria-label="Cancel" text="Cancel" allytmln="cancel-group-action" variant="link" onClick={() => setOpen(true)} />;
};
export const DeleteButtonModalTrigger: React.FC = () => {
  const { setOpen } = useModalContext();
  return <EditButtonStyled aria-label="Delete Group" allytmln="delete-group" text="Delete Group" variant="secondary" onClick={() => setOpen(true)} />;
};

export type CancelModalContentProps = {
  closeModal?: () => void;
  setActions: () => void;
  headingText?: string;
  history?: string;
  
};

export const CancelModalContent: React.FC<CancelModalContentProps> = ({
  setActions
}) => {
  const handleContinue = () => {
    setActions();
    //TODO: id argue we should return to source Tab but we need to track that. Just do vehicle for now
  };
  const { setOpen } = useModalContext();
  return (
    <>
      <TextHeading size="sm" tag="h1" tabIndex={0}>
        Are you sure you want to leave the delivery group form?
      </TextHeading>
      <TextBody size="sm" tag="span" tabIndex={0}>
        <p>You haven’t saved the information for your delivery group.</p>
        <p>
          Go Back to save your information or select Continue to leave the form.
        </p>
      </TextBody>
      <ModalButtonContainer>
        <ContinueButtonStyle
          aria-label="continue"
          text="Continue"
          variant="primary"
          allytmln="confirm-cancel-group-action"
          onClick={handleContinue}
        />
        <CancelButtonStyle
          aria-label="Go Back"
          allytmln="stop-cancel-group-action"
          text="Go Back"
          variant="secondary"
          onClick={() => setOpen(false)}
        />
      </ModalButtonContainer>
    </>
  );
};

export type DeleteModalContentProps = {
  deleteActionComposer: (cb: () => void) => () => void;
  headingText?: string;
  history?: string;
};

export const DeleteDeliveryGroupModalContent: React.FC<DeleteModalContentProps> = ({
  deleteActionComposer
}) => {
  const navigateOnContinue = () => {
    //noop
  };
  const deleteAction = deleteActionComposer(navigateOnContinue);
  const { setOpen } = useModalContext();
  return (
    <>
      <TextHeading size="sm" tag="h1" tabIndex={0}>
        Are you sure you want to delete this delivery group?
      </TextHeading>
      <TextBody size="sm" tag="span" tabIndex={0}>
        <p>All the vehicles in this delivery group will return to your list of available vehicles if you continue.</p>
      </TextBody>
      <ModalButtonContainer>
        <ContinueButtonStyle
          aria-label="Delete"
          text="Delete"
          variant="destructive"
          allytmln="confirm-group-delete"
          onClick={deleteAction}
        />
        <CancelButtonStyle
          aria-label="Go Back"
          allytmln="cancel-group-delete"
          text="Go Back"
          variant="secondary"
          onClick={() => setOpen(false)}
        />
      </ModalButtonContainer>
    </>
  );
};
