import { muiChevronLeft } from "@ally/metronome-icons";
import { GoBackStyle, Main, StyledIcon } from "./DeliveryGroupTabControlerStyled";
import DeliveryGroupTabController from "./DeliveryGroupTabController"
import { customersDashBoardRoute } from "../../routes/RouteFactory";
import { useHistory, useParams } from "react-router";
import { useGetDeliveryGroups } from "../../hooks/deliveryGroupHooks/useGetDeliveryGroups";
import { useDealershipInfo } from "../../hooks/useDealershipInfo";
import { Spinner } from "@ally/metronome-ui";
import { useGetCustomer } from "../../hooks/customerHooks/useGetCustomer";
const SingleCustomerPage: React.FC = () => {
  const history = useHistory();
  const [dealershipInfo] = useDealershipInfo();
  const dealershipNumber = dealershipInfo?.dealershipNumber;
  const goBackToCustomer = () => {
    history.push(customersDashBoardRoute());
  };
  const { id } = useParams() as any;
  const { isSuccess } = useGetDeliveryGroups(dealershipNumber, id);
  const { data: customer } = useGetCustomer(dealershipNumber, id);
  return (  
    <Main role="main">
      <GoBackStyle onClick={goBackToCustomer} tabIndex={0}>
        <StyledIcon icon={muiChevronLeft} size="xl" ariaHidden />
        Back to Commercial Customers
      </GoBackStyle>
      {!isSuccess || !customer ? <Spinner /> : 
      <DeliveryGroupTabController/>
      }
    </Main>
  );
}

export default SingleCustomerPage;
