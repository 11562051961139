import { Icon, TextHeading } from "@ally/metronome-ui";
import styled from "styled-components";


export const StyledPannel = styled.div`
  font-weight: normal;
`;

export const TextHeadingStyled = styled(TextHeading)`
  max-height: 54px;
  font-weight: bold;
  line-height: 54px;
  font-size: 24px;
  margin-top: 4px;
  color: #2a2a2a
`;

export const Heading = styled.h1`
  display: flex;
  margin-left: 22px;
  margin-bottom: 25px;
  margin-top: 20px;
  color: #650360;
  font-weight: bold;
  line-height: 54px;
  font-size: 36px;
  word-break: break-word;
`;

export const GoBackStyle = styled.div`
  box-sizing: border-box
  height: 55px;
  padding-left: 22px;
  margin-top: 12px;
  color: #0071C4;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  line-height: 54px;
  align-items: center;
  cursor: pointer;

`;

export const StyledIcon = styled(Icon)`
  width: 30px;
  height: 30px;
  margin-left: -1rem;
  margin-right: -0.25rem;
`;

export const Main = styled.div`
  div[data-testid="mui-card"] {
    box-shadow: none;
    border: none;
    background-color: #fcfcfc;
}
`
