import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { SELECT_SIGNING_OPTION_KEY_ROOT, allAssetContractsForDealershipKey, contractStatusKey, multipleCustomersKey, singleDeliveryGroupsKey } from "../../utils/react-query-key-factory";
import { ValidationError } from "../adaptorHooks/validationError";
import { RadioAction } from "../../components/Tab/DeliveryGroupTabViews/SigningOptions";

async function putSigningOption(put:<In, Out>(url:string, body:In) => Promise<Out|null>,
  dealershipNumber: string, deliveryGroupId: string, signingOption: string): Promise<void|null>{
  if(! deliveryGroupId){
    return null;
  }
  let dgEndpoint: string;
  switch(signingOption){
    case RadioAction.eSign:
      dgEndpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/invoke-embedded-signing`;
      break
    case RadioAction.addDocument:
      dgEndpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/invoke-embedded-sending`;
      break
    default:
      dgEndpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/ink-sign`;
  }
  return put<any, void>(dgEndpoint, {});
}

type SelectSigningOption = {
  signingOption: string;
  onSuccess: () => void;
  onError: (error: string[]) => void;
}

export function useSelectSigningOption(dealershipNumber: string, deliveryGroupId: string){
  const {put} = useHttp();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  return useMutation(
    [SELECT_SIGNING_OPTION_KEY_ROOT],
    async (req: SelectSigningOption) => await putSigningOption(put, dealershipNumber, deliveryGroupId, req.signingOption),
    {
      onSuccess: (data, req, context) => {
        req.onSuccess();
      },
      onSettled: (data, error, req) => {
        queryClient.refetchQueries(contractStatusKey(dealershipNumber, deliveryGroupId));
        queryClient.invalidateQueries(singleDeliveryGroupsKey(dealershipNumber ,deliveryGroupId));
        queryClient.invalidateQueries(multipleCustomersKey(dealershipNumber));
        queryClient.invalidateQueries(allAssetContractsForDealershipKey(dealershipNumber));
      },
      onError(error, req: SelectSigningOption, context){
        if(error instanceof ValidationError){
          req.onError(error.validationErrors.map(e => e.message));
          if (error.validationErrors.length > 0) {
            error.validationErrors.forEach((errorString) =>{
              analytics(emitErrorEvent, errorString.message, error.status) }
            );
          }
        }
        else {
          req.onError(["Oops something went wrong. Please try again later"]);
          analytics(emitErrorEvent, "select signing option error", (error as any).status ?? 500);
        }
      },
      retry: 2
    }
  );
}
