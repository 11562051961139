
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useHttp} from "../adaptorHooks/useHttp";
import { ValidationError } from "../adaptorHooks/validationError";
import { useAnalytics } from "../analytics/useAnalytics";
import { emitErrorEvent } from "../../analytics/apiErrors";
import { CANCEL_CONTRACT_MUTATION_KEY_ROOT, contractStatusKey, multipleDeliveryGroupsKey, singleDeliveryGroupsKey } from "../../utils/react-query-key-factory";

async function putCancelContract(put: <In,Out>(url: string, body:In) => Promise<Out|null>,
  dealershipNumber: string, deliveryGroupId: string): Promise<void|null>{
  const endpoint = `dealership/${dealershipNumber}/delivery-groups/${deliveryGroupId}/cancel-contract`;
  return put<any, void>(endpoint, {});
}

type CancelContract = {
  onError: (error: string[]) => void;
  onSuccess: () => void;
  deliveryGroupId: string;
}

export function useCancelContract(dealershipNumber: string, customerId: string) {
  const { put } = useHttp();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  return useMutation(
    [CANCEL_CONTRACT_MUTATION_KEY_ROOT],
    async (req: CancelContract) => await putCancelContract(put, dealershipNumber, req.deliveryGroupId),
    {
      onError(error, req: CancelContract, context){
        if(error instanceof ValidationError){
          req.onError(error.validationErrors.map(e => e.message));
          if (error.validationErrors.length > 0) {
            error.validationErrors.forEach((errorString) =>{
              analytics(emitErrorEvent, errorString.message, error.status) }
            );
          }
        }
        else{
          req.onError(["Oops something went wrong. Please try again later"]);
          analytics(emitErrorEvent, "cancel contract error", (error as any).status ?? 500);
        }
      },
      onSuccess: (data, req, context) => {
        req.onSuccess();
      },
      onSettled: (data, error, req) => {
        queryClient.refetchQueries(contractStatusKey(dealershipNumber, req.deliveryGroupId ));
        queryClient.invalidateQueries(singleDeliveryGroupsKey(dealershipNumber ,req.deliveryGroupId));
        queryClient.invalidateQueries(multipleDeliveryGroupsKey(dealershipNumber, customerId));
      },
      retry: 2
    }
  );
}
