import React from "react";
import { Box, Label, useModalContext } from "@ally/metronome-ui";
import { muiWarningFill } from "@ally/metronome-icons";
import { useRef, useState } from "react";
import { useHistory } from "react-router";
import { useGetDealerships } from "../../hooks/dealershipHooks/useGetDealerships";
import { useDealershipInfo } from "../../hooks/useDealershipInfo";
import {
  ButtonContainer,
  ButtonStyle,
  CancelButtonStyle,
  IconContainer,
  LabelStyle,
  ParagraphContent,
  SelectedDropdownTrigger,
  StyledIcon,
  TitleStyle,
} from "./ChangeDealershipModelStyled";
import { useAnalytics } from "../../hooks/analytics/useAnalytics";
import { emitChangeDealershipEvent } from "../../analytics/changeDealershipCustomEvent";
import { PageViews, emitPageViewEvent } from "../../analytics/pageViews";
import { customersDashBoardRoute } from "../../routes/RouteFactory";

type ModalContentProps = {
  headingText?: string;
  options?: any[];
};

export const ChangeDealershipModal: React.FC<ModalContentProps> = ({
  options
}) => {
  const [dealerInfo, setDealerInfo] = useDealershipInfo();
  const {isSuccess: dealershipsFetched, data: dealerships} = useGetDealerships();
  const [dealership, setDealership] = useState<any>(null);
  const analytics = useAnalytics();
  const history = useHistory();
  const { setOpen } = useModalContext();
  
  const onSelectDealer = () => {
    const dealer = dealerships?.find(d => d.dealershipNumber === dealership);
    const dealerNum = dealer?.dealershipNumber ?? dealerInfo?.dealershipNumber;
    const dealershipName = dealer?.name ?? "";
    const dealershipState = dealer?.state ?? "";
    const dealershipAddress = dealer?.address ?? "";
    const dealershipZip = dealer?.zip ?? "";
    const dealershipCity = dealer?.city ?? "";
  
    const currDealerInfo = {
      dealershipState,
      dealershipName: dealershipName, 
      dealershipNumber: dealerNum,
      dealershipAddress: dealershipAddress,
      dealershipZip: dealershipZip,
      dealershipCity: dealershipCity
    }
    setDealerInfo(currDealerInfo);
    analytics(emitChangeDealershipEvent);
    if(history.location.pathname === "/"){
      analytics(emitPageViewEvent, PageViews.DASHBOARD);
    }
    history.push(customersDashBoardRoute());
    setOpen(false);
  }
  
  const refValue = useRef(null);
  const pContent = "The CSG multi asset contract is available for the following dealerships. Select one to continue.";

  let dealershipSelection = undefined;
  if(dealershipsFetched){
    dealershipSelection = ( <div>
      <SelectedDropdownTrigger
      aria-label="change dealership"
      id="states"
      name="change_dealership"
      options={options}
      ref={refValue}
      value={dealership}
      placeholder="-Select Dealership Number-"
      onChange={(e: any) => setDealership(e.currentTarget.value)}
    />
    <ButtonContainer>
      <ButtonStyle
        aria-label="Update"
        text="Update"
        variant="primary"
        allytmln="update-change-dealership"
        onClick={onSelectDealer}
      />
      <CancelButtonStyle
        aria-label="Cancel"
        text="Cancel"
        variant="secondary"
        allytmln="cancel-change-dealership"
          onClick={() => setOpen(false)}
      />
    </ButtonContainer>
    </div>);
  }
  return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        aria-label="Change Dealership"
      >
        <TitleStyle>Select Dealership</TitleStyle>
        <IconContainer>
          <StyledIcon
          icon={muiWarningFill}
          size="xl"
            fill="plum"
            ariaHidden
          />
          <ParagraphContent>{pContent}</ParagraphContent>
        </IconContainer>
        <Label htmlFor="ChangeDealershipStatus">
          <LabelStyle>Dealership (PDN)</LabelStyle>
        </Label>
        {dealershipSelection}
      </Box>
  );
};
